<template>
  <div>
    <TopHeader/>
    <uitgelicht :persons="persons"/> 
    <Footer/>
  </div>
</template>

<script>

import json from "@/data.json"; 
import TopHeader from "../components/TopHeader.vue";
import Footer from "../components/Footer.vue";
import Uitgelicht from "../components/Uitgelicht.vue";

export default {
  name: 'Home',
  components: {
    TopHeader, Footer, Uitgelicht
  },
  data() {
    return {
      "persons": json["persons"]
    }
  },

  created() {
    if ( window.location !== window.parent.location ) {
      var icon = document.querySelector(".fullScreenIcon");
      if (icon) icon.style.display = "block";
    }
  },

  methods: {

    fullName(p) {
      const fullname =  [p.fname, p.prefix, p.sname].join(" ");
      return fullname.trim() ? fullname : p.id;
    },

    getRandomPersons(n) {
      return this.persons
        .map(x => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map(a => a.x)
        .slice(0, n);

    }

  }
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}
.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}
.btn:hover {
  background: #666;
}

.name-link {
  text-align: center;
  padding-top: 20px;
}

.test-date {
  text-align: center;
  font-size: small;
  color: #555;
}
#app {
  background-color: white;
  background: no-repeat center center fixed;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>

