import moment from 'moment'

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {

    Vue.prototype.$formatDate = function(date, prefix) {
      moment.locale("nl")

      let result = "";
      if (prefix) result += prefix + " ";

      let dateItems = date.split("-");
      if (dateItems.length==3) result+=moment(date).format("D MMMM YYYY")
      else if (dateItems.length==2) result+=moment(date).format("MMMM YYYY")
      else if (dateItems.length==1) result+=moment(date).format("YYYY")
      
      return result;
    };

    Vue.prototype.$formatYear = function(date, prefix) {
      moment.locale("nl")

      let result = "";
      if (prefix) result += prefix + " ";

      result+=moment(date).format("YYYY")

      return result;
    };

    Vue.prototype.$formatYearShort = function(date, prefix) {
      let prefix_short = prefix || "";
      // prefix_short = prefix_short.replace("voor of in","<=");
      return this.$formatYear(date, prefix_short);
    }
    


  }
};
