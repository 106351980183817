import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Person from '../views/Person.vue'
import Periodes from '../views/Periodes.vue'
import Over from '../views/Over.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/p/:id',
    name: 'Person',
    component: Person
  },
  {
    path: '/personen',
    name: 'Personen',
    redirect: '/components/com_maisinternet/index.php',
  },
  {
    path: '/periodes',
    name: 'Periodes',
    component: Periodes
  },
  {
    path: '/over',
    name: 'Over',
    component: Over
  }

]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
