<template>
  <div>
    <CoolLightBox 
        :items="images" 
        :index="index"
        @close="index = null">
    </CoolLightBox>
    
    <v-row class="item event-item" no-gutters>
      <v-col md="2" cols="12">  <!--sm="12" werkt niet.....-->
        <div v-if="event.date.datepart1" class="day-item text-md-right">{{event.date.datepart1}}</div>
        <div class="year-item text-md-right">{{event.date.datepart2}}</div>
      </v-col>
      <v-col md="1" class="timeline-block">
        <div class="dot"></div>
        <div class="vl"></div>
      </v-col>
      <v-col cols="12" md="9" class="item-content">

        <div class="card" :class="event.type">
          <div class="card-body" >
            <!-- <div> -->
              <p v-if="event.title" class="title" v-html="event.title"></p>
              <p v-html="beschrijving2"></p>
              <blockquote v-if="event.quote">{{event.quote.text}}
                <span v-if="event.quote.source_url"> (<a :href="event.quote.source_url" :title="event.quote.source_description">bron</a>)</span>
              </blockquote>

              <div v-if="event.image">
                <img :src="event.image.crop" @click="index=0" class="img-fluid">
              </div>

              <div v-if="event.moreinfo || event.links">
                <div v-if="event.description" @click="showMoreInfo=!showMoreInfo" class="btn-more-info pull-right">
                  {{showMoreInfo ? 'verberg' : 'bron'}} <i :class="['fa', showMoreInfo ? 'fa-times' : 'fa-angle-down' ]"></i>
                </div>
                
                <ul class="more-info" v-show="showMoreInfo || !event.description">
                  <li :key="link.url" v-for="link in event.links">
                    <a :href="link.url" :title="link.description" target="_blank">{{link.description}}</a>
                  </li>
                
                  <li v-if="event.moreinfo">
                    <a :href="event.moreinfo.url" target="_blank">{{event.moreinfo.description}}</a> 
                  </li>

                  <li v-if="event.image && (event.image.source || event.image.description)">
                    <a :href="event.image.source" target="_blank">{{event.image.description || event.image.source}}</a> 
                  </li>

                </ul>
              </div>

            </div>
         </div>
        <!-- </div> -->
      </v-col>
    </v-row>

  </div>
    
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

export default {
    name: "EventItem",
    components: {
      CoolLightBox
    },
    props: ["person", "event"],
    data() {
      return {
        id: "",
        crop: "",
        image: undefined,
        showSourceModal: false,
        title : "",
        description: "",
        moreinfo: {
          description: "",
          url: ""
        },
        showMoreInfo: false,
        index: null
      }
    },
    computed: {
      beschrijving2() {
        return this.microTemplate(this.event.description, this.person);
      },
      images() {
        if (!this.event.image) return [];
        // console.log(this.event.image.crop,this.event.image.full)
        return [ {
          title: "",
          description: this.event.image.description,
          src: this.event.image.full
        }]
      }
    },
    methods: {
      // micro templating, sort-of
      microTemplate(src, data) {
        // replace {{tags}} in source
        return src.replace( /\{\{([\w\-_.]+)\}\}/gi, function( match, key ) {
          console.log(key)
          // walk through objects to get value
          var value = data;
          key.split('.').forEach( function( part ) {
            value = value[ part ];
          });
          return value;
        })
      }
    }
}
</script>

<style>
  
  .contextitem { 
    margin-left: 50px;
    margin-right: 50px;
  }
  .contextitem .card-body {
    /* background-color:  white; */
  }
  .more-info {
    margin-top: 10px;
  }

  ul.more-info {
    list-style: circle;
    margin-left: 20px;
  }

  ul.more-info li {
    margin-bottom: 20px;
  }

  .btn-more-info {
    text-align: right;
    /* border: 1px solid red; */
    padding-top: 10px;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #427e74;
    user-select: none;
    cursor: pointer;
  }

  .more-info a {
    /* border: 1px solid red; */
    /* display: inline-block; */
    max-width: 85%;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    overflow: hidden;
  }
  .frm, .form-group {
    margin: 0px !important;
    padding: 0px !important;
  }
  /* .text-editor {
    background-color: white;
    padding: 6px;
  } */
  .title {
    font-weight: bold;
  }
  label {
    font-weight: bold;
  }

  @media print {
    .event {
      break-inside: avoid;
    }
  }

  .overlay a {
    color: white;
  }

  .card img[alt$="fullwidth"]{
    width:  100%;
    display: block;
  }

  body { 
    background-color: white;
    background: no-repeat center center fixed;
    /*url('../img/bg.jpg') */
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .card .card-body p {
    /*margin-bottom: 0px;*/
  }
    
  /*@font-face {
    font-family: 'Roboto Bold';
    src: url('../font/roboto/Roboto-Bold.woff2') format('woff2'),
        url('../font/roboto/Roboto-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Roboto Regular';
    src: url('../font/roboto/Roboto-Regular.woff2') format('woff2'),
        url('../font/roboto/Roboto-Regular.woff') format('woff');
  }*/

  div.title {
    margin-left: 20px;
    /* margin-top: 20px; */
  }

  div.title h2 {
    margin-left: 20px;
  }

  .nameblock h1 {
    font-weight: bold;
  }

  .container p {
    font-size: 1.2rem;
  }

  div.title h1, div.title h2 {
    background-color: white;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 2px 15px 2px 15px;
  }

  .thumb-post img {
    object-fit: fit; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    width: 100%;
    max-height: 250px;
    /*margin-bottom: 1rem;*/
  }

  .card-body img {
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    width: 100%;
    max-height: 250px;
    /*margin-bottom: 1rem;*/
  }

  .date-block {
    /*border-right: 1px solid black;*/
  }

  .day-item {
    white-space: nowrap;
  }

  .card-body {
    background-color: #eee;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px
  }
  .year-item {
    font-size: 2rem;
  }
  a.lees-meer {
    padding: 4px 10px 4px 10px;
  }

  .card-body a, a.lees-meer {
    color: #427e74 !important;
  }

  /*.col-1 { border: 1px solid red }*/

  .vl {
    border-left: 1px solid black;
    height: 100%;
    margin-left: 20px;
  }

  .item hr {
    width: 50%;
    border: 3px solid black;
  }

  .death-line {
    background-color: white;
    margin-bottom: 20px;
  }

  .item .vl {
    border-left: 1px dashed black;

  }

  .dot {
    /*border-left: 2px solid black;*/
    /*height: 100%;*/
    margin-left: 14px;
    margin-top: 33px;
    position: absolute;
    background-color: white;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 1px solid black;
  }

  blockquote {
    background-color: white;
    font-style: italic;
    padding: 10px 10px 10px 10px;
  }

  .date-block, .timeline-block {
      background-color: white;
      /* border:1px solid red; */
  }

  .card.literature {
    margin-top: 30px;
    margin-left: 40px;
  }

  .card.literature .card-body {
    background-color: #ddd;

  }

</style>