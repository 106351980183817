<template>
  <div>

    <div class="omni-icon">
      <a href="/"> </a>
    </div>

    <div class="omni">
      <header>
        <div class="header-bar">
          <div class="inner">
            <div class="name">
              <a href="/"> Utrechtse<br/> Bestuurders</a>

              <a href="/" class="subtitle">
                900 jaar stadsbestuur
              </a>
            </div>

            <div class="menu">
              <div>
                <ul id="menu-main">
                  <li :class="{on: ($route.name=='Home')}">
                    <a href="/">
                      <span>Uitgelicht</span>
                    </a>
                  </li>

                  <li :class="{on: ($route.name=='Personen')}">
                    <a href="/personen">
                      <span>Alle Personen</span>
                    </a>
                  </li>

                  <li :class="{on: ($route.name=='Periodes')}">
                    <a href="/periodes">
                      <span>Periodes</span>
                    </a>
                  </li>

                  <li :class="{on: ($route.name=='Over')}">
                    <a href="/over">
                      <span>Over</span>
                    </a>
                  </li>

                </ul>
              </div>

            </div>
          </div>

          <div class="outer"></div>
        </div>
      </header>
    </div>
  </div>
</template>


<style scoped>

.header-bar .inner .name > a.subtitle {
  font-size: 1.1em;
}

</style>

<script>

export default {

  computed: {

    aboutPage() {
      const rnd = Math.floor(Math.random()*1000);
      return `https://hetutrechtsarchief.nl/utrechtse-bestuurders?${rnd}`; //#anchor-over_dit_project`;
    }
    
  }

}

</script>
