<template>
  <div>
    <TopHeader/>
    <Jumbotron title="Over deze website" bgImage="img/bg-slag.jpg">
      <template v-slot:subtitle>
        <p>Informatie over de totstandkoming van deze website <br/>vindt u op de <a target="_top" href="https://hetutrechtsarchief.nl/utrechtse-bestuurders">website van Het Utrechts Archief</a>.</p>
      </template>
    </Jumbotron>
    <Footer/>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import Footer from "../components/Footer.vue";
import Jumbotron from '../components/Jumbotron.vue';

export default {
  name: "Personen",
  components: {
    TopHeader,
    Footer,
    Jumbotron,
  },

  created() {
    if ( window.location !== window.parent.location ) {
      var icon = document.querySelector(".fullScreenIcon");
      if (icon) icon.style.display = "block";
    }
  }
};
</script>
