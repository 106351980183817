<template>
  
  <div>
    <TopHeader/>
    <Jumbotron 
      :title="person | fullName"
      :bg-image="person.thumb">

      <template v-slot:subtitle>
        <p v-if="person.tagline" class="tagline">{{person.tagline}}</p>
        <p>{{getBirthDeathString(person)}}</p>
        <p v-if="person.long_summary" class="long_summary">{{person.long_summary}}</p>
      </template>

    </Jumbotron>

    <div class="field-item odd" style="padding-top: 20px;"> <!--fixme-->
      <!-- <div class="block-ck"> -->
        <Events :person="person"/>  
      <!-- </div> -->
    </div>

      <!-- <div class="block-location-hot"> -->
        <!-- <div class="band"> -->
          <!-- <div class="section-header"> -->
            
            <!-- <v-container>
              <v-row>
                <v-col md="12">
                  <p v-if="person.long_summary" class="long_summary">{{person.long_summary}}</p>
                </v-col>
              </v-row>
            </v-container>
             -->

            <!-- <div class="row nameblock p-4 mb-4 mb-md-0">  
              <div class="d-none d-lg-block col-12 d-md-block col-md-2 thumb-post">
                <img :src="person.thumb" class="rounded-circle"/>
              </div>
              <div class="col-12 col-md-10">              
                <h1 class="person-full-name">
                  <span class="fname">{{person.fname}} </span>
                  <span class="no-border"> </span>
                  <span class="prefix">{{person.prefix}}</span>
                  <span class="no-border"> </span>
                  <span class="sname">{{person.sname}}</span>
                  <span class="sname_alt" v-if="person.sname_alt"> ({{person.sname_alt}})</span>
                </h1>
                <p class="person-birth-death" v-html="birthdeath"></p>
                <p v-if="person.tagline" class="tagline">{{person.tagline}}</p>
                <p v-if="person.long_summary" class="long_summary">{{person.long_summary}}</p>
              </div>
            </div> -->
              
            

          <!-- </div> -->
        <!-- </div> -->
      <!-- </div> -->
    <!-- </div>   -->


    

    
    
    <Footer/>

  </div>

<!-- 
  
  <div id="app" :style="bgImageStyle()">
    <main class="page-content pt-4" aria-label="Content">
      <div class="container">
        <v-theme-provider dark>
          <div class="row nameblock p-4 mb-4 mb-md-0">  
            <div class="d-none d-lg-block col-12 d-md-block col-md-2 thumb-post">
              <img :src="person.thumb" class="rounded-circle"/>
            </div>
            <div class="col-12 col-md-10">              
              <h1 class="person-full-name">
                <span class="fname">{{person.fname}} </span>
                <span class="no-border"> </span>
                <span class="prefix">{{person.prefix}}</span>
                <span class="no-border"> </span>
                <span class="sname">{{person.sname}}</span>
                <span class="sname_alt" v-if="person.sname_alt"> ({{person.sname_alt}})</span>
              </h1>
              <p class="person-birth-death" v-html="birthdeath"></p>
              <p v-if="person.tagline" class="tagline">{{person.tagline}}</p>
              <p v-if="person.long_summary" class="long_summary">{{person.long_summary}}</p>
            </div>
          </div>
        </v-theme-provider>
        <Events :person="person"/>  
      </div>
    </main>

  </div> -->
</template>

<script>
import Events from '../components/Events.vue'
import TopHeader from '../components/TopHeader.vue'
import Jumbotron from '../components/Jumbotron.vue'
import moment from 'moment'
import json from "@/data.json"; 

export default {
  name: 'Person',
  components: {
    Events, TopHeader, Jumbotron
  },

  data() {
    const id = this.$route.params.id;
    const idx = json["persons"].findIndex(person => person.id === id);
    const person = json["persons"][idx];

    return {
      "person": person
    }
  },

  created() {
    if ( window.location !== window.parent.location ) {
      var icon = document.querySelector(".fullScreenIcon");
      if (icon) icon.style.display = "block";
    }
  },

  methods: {
    bgImageStyle() {
      return `background-image: url('${this.bg}')`;
    },

    formatDate(date, prefix) {
      moment.locale("nl")

      let result = "";
      if (prefix) result += prefix + " ";

      let dateItems = date.split("-");
      if (dateItems.length==3) result+=moment(date).format("D MMMM YYYY")
      else if (dateItems.length==2) result+=moment(date).format("MMMM YYYY")
      else if (dateItems.length==1) result+=moment(date).format("YYYY")
      
      return result;
    },

    getBirthDeathString(person) {
      moment.locale("nl")
      let result = "";
      const p = person;
      if (p.bplace) result += `${p.bplace}, `;
      //${this.formatDate(p.bdate, p.bdate_prefix)}
      if (p.bdate) result += `${p.bdate} `;
      if (p.bdate_prefix) result += `${p.bdate_prefix} `;
      if ((p.bplace || p.bdate) && (p.dplace || p.ddate) ) result += " - ";
      if (p.dplace) result += `${p.dplace}, `;
      // ${this.formatDate(p.ddate, p.ddate_prefix)}
      if (p.ddate_prefix) result += `${p.ddate_prefix} `;
      if (p.ddate) result += `${p.ddate} `;
      return result;
    },
    

  },

  filters: {
    fullName(person) {
        return [person.fname, person.prefix, person.sname].join(" ");
    },


  }
  
}
</script>

<style>

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}
.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}
.btn:hover {
  background: #666;
}
#app {
  background-color: white;
  background: no-repeat center center fixed;
  /*url('../img/bg.jpg') */
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.tagline {
  font-style: italic;
  font-weight: bold;
}

.long_summary {
  font-weight: normal; 
  /* font-family: serif; */
  text-align: center;
}

.sname_alt {
  font-size: smaller;
  color: #888;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: dotted;
}

.nameblock {
  background-color: #202020;
  color: white;
}

@media print {
  .nameblock * {
    color: black;
  }
}

</style>

