<template>
    <!-- <div> -->
        <!-- <CoolLightBox 
            :items="images" 
            :index="index"
            @close="index = null">
        </CoolLightBox> -->

        <!-- <div class="events" > -->
        <v-container>
            <EventItem class="event" :person="person" :event="event" :key="event.id" v-for="event in events"/>
             <!-- @image-click="onImageClick"/> -->
        </v-container>
    <!-- </div> -->
</template>

<script>
import EventItem from './EventItem.vue'
// import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import json from "@/data.json"; 

export default {
    name: "Events",
    components: {
        EventItem
        // CoolLightBox
    },
    props: ["person"],

    data() {
        return {
            "events": json["events"].filter(event => event.personId === this.person.id),
            index: null //index for CoolLightBox
        }
    },

    // methods: {
    //     onImageClick(id) {
    //         const idx = this.events.findIndex(event => event.id === id);
    //         this.index = idx;
    //     },
    // }
    // computed: {
    //     images() {
    //         if (!this.events.length) return [];

    //         else return this.events.map((event) => {
    //             return (typeof(event.image)==='object' ? event.image.full : "");
    //         });
    //     }
    // }

}
</script>

<style scoped>

</style>