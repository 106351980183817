<template>

  <div>
      
  <article class="node node-page view-mode-full clearfix node-3">
    <div
      class="
        paragraphs-items
        paragraphs-items-field-block
        paragraphs-items-field-block-full
        paragraphs-items-full
      "
    >
      <div
        class="
          field field-name-field-block field-type-paragraphs field-label-hidden
        "
      >
        <div class="field-items">
          <div class="field-item even">
            <div
              class="block-header loaded"
              data-uri=""
              :style="`background-image: url('${bgImage}');`"
            >
              <div>
                <div>
                  <h1>{{ title }}</h1>
                  <p>{{ subtitle }}</p>
                   <slot name="subtitle"/>
                </div>
              </div>
            </div>
          </div>
          

        </div>
      </div>
    </div>
  </article>

  </div>

</template>

<script>
export default {
  name: "Jumbotron",
  props: ["bgImage", "title", "subtitle"],

};

</script>
