<template>
  <div class="field-item odd">
    <div class="block-location-hot">
      <div class="band">
        <div class="section-header">
          <div>
            <p>Een aantal personen uit de geschiedenis van het stadsbestuur worden hieronder uitgelicht.<br/>
               Daarnaast kunt u zoeken in de <a href="/personen">database met alle Utrechtse Bestuurders</a> en is er een uitleg over het <a href="/periodes">stadsbestuur per periode</a>.
            </p>
          </div>

          <div></div>
        </div>
    
        <div class="view-card">
          
          <div class="card" v-for="person in persons" :key="person.id">
            <router-link :to="`/p/${person.id}`">
              <div class="head">
                <div class="outer">
                  <img
                    :src="person.thumb"
                    width="540"
                    height="540"
                    alt=""
                  />
                </div>

                <div class="inner">
                  <span class="name">{{person | fullName}}
                    <span class="name-sub">{{$formatYearShort(person.bdate, person.bdate_prefix)}} - {{$formatYearShort(person.ddate, person.ddate_prefix)}}</span>
                  </span>
                </div>
              </div>

              <div class="core">
                <em>{{person.tagline}}</em>
              </div>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Uitgelicht',

  props: [ "persons" ],

  filters: {
      fullName(person) {
        return [person.fname, person.prefix, person.sname].join(" ");
      },
      asYear(date) {
        return moment(date).format("YYYY");
      }
  }
}

</script>

<style scoped>
.card-list>a span {
    color:red;
}
</style>